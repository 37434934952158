export const ChangeTabMixin = {
    methods: {
        changeFrameCustom(frameName, framesObj) {
            if (!framesObj[frameName]) {
                for (let frame of Object.keys(framesObj)) {
                    if (framesObj[frame]) {
                        framesObj[frame] = false
                        break;
                    }
                }
                framesObj[frameName] = true
            }
        },
    }
}