export const ModalsCommonMixin = {
    data() {
        return {
            modalCloseTimeout: 500,
        }
    },
    methods: {
        closeWithDelay() {
            setTimeout(() => {
                this.$emit('close')
            }, this.modalCloseTimeout)
        },
    }
}