<template xmlns="http://www.w3.org/1999/html">
    <div class="confirm-window">
        <div class="block">

            <div class="confirm"
                 v-if="errors === null && ['pun', 'buyUnPun', 'set', 'setPrefix', 'grTransfer', 'moneySend', 'changePassword', 'changeEmail', 'teleportSpawn', 'buyWarp'].includes(operation) && !throwSuccess">
                <div class="b-text"
                     v-if="operation !== 'grTransfer' && operation !== 'buyUnPun' && operation !== 'pun' && operation !== 'moneySend' && operation !== 'changePassword' && operation !== 'teleportSpawn' && operation !== 'changeEmail' && operation !== 'buyWarp'">
                    Вы действительно
                    хотите установить
                    {{ operation === 'set' ? 'новый' : 'новое' }}
                    {{ formatedType[type].fname ? formatedType[type].fname : formatedType[type] }} ?
                </div>

                <div class="b-text" v-if="operation === 'grTransfer'">Вы действительно хотите перевести: <br>
                    <span style="color:lawngreen;">{{ data.transferCost }} ₽ </span> в <span
                        style="color: #4ad5ea">{{ data.gracesSummary }} GR </span> ?
                </div>

                <div class="b-text" v-if="operation === 'moneySend'">Вы действительно хотите перевести
                    <span style="color: orange">{{ data.sendMoneyCost }} ₽ </span>игроку <span
                        style="color: lawngreen;">{{ data.sendMoneyNickname }} </span>?
                </div>

                <div class="b-text" v-if="operation === 'buyUnPun'">
                    Вы действительно хотите внести залог за <span style="color: red">{{ type }}</span> на сервере <span
                    style="color: lawngreen;">{{ data.serverName }} </span> <br> Стоимость снятия: <span style="color: orange">{{cost}} ₽</span>
                </div>

                <div class="b-text" v-if="operation === 'pun'">
                    Вы действительно хотите выдать
                    <span style="color: orangered">{{ data.format.type }}</span>
                    игроку
                    <span style="color: lawngreen;">{{ data.pun.player }} </span>
                    на сервер
                    <span style="color: #4ad5ea;">{{ data.pun.server_name }} </span>
                    <span v-show="data.pun.expiry > 0">
                        сроком на
                        <span style="color: orangered;">{{ data.format.time }}</span>
                    </span>
                    по причине
                    <span style="color: orange;">{{ data.pun.reason }} </span>?
                </div>

                <div class="b-text" v-if="operation ==='changePassword'">Вы действительно хотите сменить пароль?
                </div>

                <div class="b-text" v-if="operation ==='changeEmail'">Вы действительно хотите сменить Email?
                </div>

                <div class="b-text" v-if="operation ==='teleportSpawn'">Вы действительно хотите телепортироваться на
                    спавн?
                </div>

                <div class="b-text" v-if="operation === 'buyWarp'">Вы действительно хотите купить
                    {{
                        type === 'single_warp' ? 'одноразовый варп' : type === 'multi_warp' ? 'многоразовый варп' : 'странный варп'
                    }}?
                </div>

                <div class="smooth_border" style="margin-top: 10px"/>


                <div class="b-text"
                     v-if="['hd_cape', 'cape', 'hd_skin', 'prefix', 'single_warp', 'multi_warp'].includes(type)"
                     style="padding-top: 6px">
                    Эта
                    услуга будет стоить {{ this.checkTextureCost(type, formatedType[type].cost) }} ₽
                </div>

                <div class="btn-block">
                    <div class="b-confirm-btn" @click="requestsPipeData">Да</div>
                    <div class="b-confirm-btn" @click="$emit('close')">Нет</div>
                </div>
            </div>

            <div class="confirm" v-if="errors === null && operation === 'delete' && !throwSuccess">
                <div class="b-text"> Вы действительно хотите удалить свой
                    {{ formatedType[type].fname ? formatedType[type].fname : formatedType[type] }} ?
                </div>

                <div class="smooth_border" style="margin-top: 10px"/>

                <div class="btn-block">
                    <div class="b-confirm-btn" @click="requestsPipeData">Да</div>
                    <div class="b-confirm-btn" @click="$emit('close')">Нет</div>
                </div>
            </div>

            <div class="success" v-if="throwSuccess">
                <div class="b-text">Операция успешно выполнена. </div>
            </div>

            <div class="success" v-if="operation === 'screen'">
                <div style="text-align: center;color: white;padding: 5px">
                    Скриншот с игрока <span style="color: greenyellow">{{data.player}}</span> на сервере <span style="color: greenyellow">{{data.server}}</span>
                    <br>
                    получен <span style="color: yellow">{{data.date}}</span> (часовой пояс: UTC{{ (new Date()).getTimezoneOffset() > 0 ? '-' : '+' }}{{ Math.abs((new Date()).getTimezoneOffset()/60)  }})
                    <br>
                    Уникальный идентификатор: <span style="color: orangered">{{ data.id }}</span>
                </div>
                <img style="width: 1280px;" :src="data.url"/>
            </div>

            <div class="b-error" v-if="errors !== null">
                <div class="b-text" v-for="error in errors._all"> {{ error }}
                </div>
                <div class="b-text" v-for="error in errors.prefix"> {{ error }}
                </div>

                <div class="smooth_border" style="margin-top: 10px"/>

                <div class="b-confirm-btn" @click="$emit('close')">Я понял</div>
            </div>


        </div>
    </div>
</template>

<script>
import {PreloaderMixin} from "../../mixins/PreloaderMixin";
import {ModalsCommonMixin} from "../../mixins/ModalsCommonMixin";


export default {
    name: "confirmModal",
    mixins: [PreloaderMixin, ModalsCommonMixin],
    props: ["type", "operation", "data", "cost"],

    data() {
        return {
            noFileSelected: false,
            errors: null,

            throwSuccess: false,
            throwError: false,
            formatedType: {
                'skin': 'обычный скин',
                'cape': {'fname': 'обычный плащ', 'cost': 49},
                'hd_skin': {'fname': 'HD скин', 'cost': 79},
                'hd_cape': {'fname': 'HD плащ', 'cost': 99},
                'SKIN': 'скин',
                'CAPE': 'плащ',
                'prefix': {'fname': 'оформление чата', 'cost': this.cost},
                'single_warp': {'fname': 'одноразовый варп', 'cost': 50},
                'multi_warp': {'fname': 'многоразовый варп', 'cost': 250},
            },
        }
    },

    methods: {
        responsePipeData(data) {
            if (['set', 'delete'].includes(this.operation)) {
                let payload = {
                    'type': this.type,
                    'data': data,
                    'operation': this.operation
                }
                this.$root.$emit('updVisualPresentation', payload)
            } else if (['setPrefix', 'grTransfer', 'sendMoney', 'buyWarp'].includes(this.operation))
                this.$root.$emit('lkRenewIsSuccess', 'OK')
            else if (this.operation === 'changePassword') {
                this.$root.redirect('/')
            }
        },

        errorPipe(err, manual = false) {
            if (!manual) {
                try {
                    this.errors = err.response.data.errors;
                } catch {
                    this.errors = {'_all': ['Внутренняя ошибка сервера. Повторите запрос позднее']}
                }
            } else this.errors = {'_all': [err]}
            console.log(err)
            try {
                if ('message' in err.response.data) {
                    console.log('Другой формат ошибки с бэка')
                    this.errors = {'_all': [err.response.data.message]}
                }
            } catch {
                console.log('Err не имеет вложенной даты')
            }

            this.throwError = true
            this.preloaderStop('.confirm-window')
        },

        thenWrapp(data) {
            this.responsePipeData(data)
            this.preloaderStop('.confirm-window')
            this.throwSuccess = true
            this.closeWithDelay()
        },

        checkTextureCost(type, cost) {
            let c = cost
            let txp = this.$root.getPlayerData().texture_permissions

            if (txp !== undefined) {
                if (Object.keys(txp).includes(type) && ((new Date(txp['type'].end)).getTime() - Date.now()) > 0) {
                    return 0
                }
            }

            return c
        },

        setSkinRequest() {
            let fileData = new FormData();
            fileData.append('file', this.data);
            require('axios').post(`/api/custom/textures/${this.type}`, fileData).then(
                data => {
                    this.thenWrapp(data)
                }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка при установке скина.')
                console.log(err)
            })
        },

        deleteSkinRequest() {
            require('axios').delete(`/api/custom/textures/${this.type}`).then(
                data => {
                    this.thenWrapp(data)
                }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка при удалении скина.')
                console.log(err)
            })

        },

        punRequest() {
            require('axios').post(`/api/moder/pun`, this.data.pun).then(
                data => {
                    this.thenWrapp(data)
                }).catch(
                err => {
                    this.errorPipe(err)
                    console.log('Ошибка при выдаче наказания.')
                    console.log(err)
                }
            )
        },

        setPrefixRequest() {
            require('axios').post('/api/donate/prefix', {
                'server_name': this.data.serverName,
                'prefix': this.data.prefixFeatureText
            }).then(data => {
                this.thenWrapp(data)
            }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка изменения кастомизации.')
                console.log(err)
            })
        },

        rubToGrTransferRequest() {
            require('axios').post('/api/donate/transfer_to_server', {
                'server_name': this.data.serverName,
                'transfer': this.data.transferCost
            }).then(data => {
                this.thenWrapp(data)
            }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка при переводе рублей в GR')
                console.log(err)
            })
        },

        sendMoneyRequest() {
            let payload = {
                'name': this.data.sendMoneyNickname,
                'transfer': this.data.sendMoneyCost,
                'comment': this.data.sendMoneyComment
            }
            require('axios').post('/api/donate/transfer', payload).then(data => {
                this.thenWrapp(data)
            }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка при переводе валюты другому игроку')
                console.log(err)
            })
        },

        changeAuthData() {
            let payload = {
                'email': this.operation === 'changePassword' ? this.$root.getPlayerData().email : this.data.newEmail,
                'password': this.data.newPassword,
                'old_password': this.data.oldPassword,
            }
            require('axios').post('/api/auth/change', payload).then(data => {
                this.thenWrapp(data)
            }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка при смене авторизационных данных.')
                console.log(err)
            })
        },

        buyWarp() {
            let warpType = this.type === 'single_warp' ? 0 : this.type === 'multi_warp' ? 1 : -1;

            let payload = {
                'server_name': this.data.serverName,
                'warp_type': warpType
            }
            require('axios').post('/api/donate/warp', payload).then(data => {
                this.thenWrapp(data)
            }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка при покупке варпа')
                console.log(err)
            })
        },

        buyUnPun() {
            let payload = {id: this.data.punId, server_id: this.data.serverId}
            require('axios').post('/api/donate/unpun', payload).then(data => {
                this.thenWrapp(data)
                this.$root.$emit('lkRenewIsSuccess', 'PUNOK')
            }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка при внесении залога за косяк пользователя.')
                console.log(err)
            })
        },

        teleportSpawnRequest() {
            let payload = {
                'server_name': this.data.serverName
            }
            require('axios').post('/api/teleport', payload).then(data => {
                this.thenWrapp(data)
            }).catch(err => {
                this.errorPipe(err)
                console.log('Ошибка при телепорт на спавн')
                console.log(err)
            })
        },

        requestsPipeData() {
            this.preloaderStartModal('.confirm-window')
            switch (this.operation) {
                case 'set':
                    if (this.data !== null)
                        this.setSkinRequest()
                    else {
                        console.log('Файл для загрузки не выбран.')
                        this.noFileSelected = true
                        this.preloaderStop('.confirm-window')
                    }
                    break;
                case 'delete':
                    this.deleteSkinRequest()
                    break;
                case 'pun':
                    this.punRequest()
                    break;
                case 'setPrefix':
                    this.setPrefixRequest()
                    break;
                case 'grTransfer':
                    this.rubToGrTransferRequest()
                    break;
                case 'moneySend':
                    this.sendMoneyRequest()
                    break;
                case 'changePassword':
                    this.changeAuthData()
                    break;
                case 'changeEmail':
                    this.changeAuthData()
                    break;
                case 'buyWarp':
                    this.buyWarp()
                    break;
                case 'teleportSpawn':
                    this.teleportSpawnRequest()
                    break;
                case 'buyUnPun':
                    this.buyUnPun()
                    break;
                default:
                    console.log('Посылаемой операции не существует!')
            }
        },

    },

    mounted() {
        if (this.data === null && this.operation === 'set')
            this.errorPipe('Сначала необходимо выбрать файл. Нажмите на кнопку обзор.', true)
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/vars";
@import "src/assets/scss/mixins";
@import "src/assets/scss/animations";


.confirm-window {
    @include flexible-column();
    padding: 36px 50px;
    border-radius: 7px;
    background-color: $shitty-hex-gray;
    border: 1px solid rgba(128, 128, 128, 0.13);
    font-family: $main-font;
}

.success,
.confirm,
.b-error {
    @include flexible-column();
}

.b-text {
    @include modals-text-blx();
}

.b-confirm-btn {
    @include main-button(4px, 30px);
    margin-top: 10px;
}

.btn-block {
    @include flexible-row-sp-ev();
    width: 100%;
}

.smooth_border {
    width: 95%;
    height: 1px;
    border-bottom: 1px groove gray;
}

</style>