export const PlayerLiveSearchMixin = {
    methods: {
        liveSearchPlayer(liveSearchPlayerName, limit=7) {
            if(liveSearchPlayerName.length < 3 || this.liveSearchOperation !== null)
                return
            this.liveSearchOperation = require('axios').get(`/api/search/player/${liveSearchPlayerName}?limit=${limit}`).then(
                data => {
                    this.liveSearchList = []
                    data.data.forEach(i => {
                        this.liveSearchList.push(i.name)
                    })
                    this.liveSearchOperation = null
                }
            ).catch(err => {
                this.liveSearchOperation = null
            })
        },
    },
    data() {
        return {
            liveSearchOperation: null,
            liveSearchList: []
        }
    }
}